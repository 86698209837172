.personal-info {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 25px;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
}
.profile-pic-section {
    width: 48%;
}
.user-img-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-pic {
    width: 40%;
    border-radius: 100px;
}
.user-info-section {
    width: 48%;
}
.edit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.select-logo {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.change-log {
    font-size: 12px;
    text-decoration: underline;
    color: #2f95dc;
    margin-right: 5px;
    cursor: pointer;
}