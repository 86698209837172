.create-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.agent-wrapper {
    background-color: #ffffff;
    padding: 30px;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
    width: 50%;
    /* display: flex;
    justify-content: space-between; */
}
.agent-content-wrapper {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-bottom: 25px;
}
.agent-main-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.agent-icon {
    /* width: 5%; */
}
.agent-name {
    /* font-size: 14px;
    width: 10%;
    padding-left: 10px; */
}
.agent-descriptoin {
    /* font-size: 14px;
    width: 30%;
    padding-left: 10px; */
}
.agent-created-date {
    /* font-size: 14px;
    width: 15%;
    padding-left: 10px; */
}
.agent-status {
    /* font-size: 14px;
    width: 10%;
    padding-left: 10px; */
}
.agent-manage-btn-wrapper {
    /* width: 20%;
    text-align: right; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.w-48{
    width: 48%;
}
.verticle-agent{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.field-title {
    width: 25%;
}
.agent-vertical-value {
    width: 75%;
}
.agent-list-header {
    padding: 12px 30px 0px 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.available {
    background: #E0FFE2;
    border-radius: 39px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #009F06;
    height: 30px;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-btn{
    margin-left: 10px;
    cursor: pointer;
}
.tools-list-active {
    background-color: #2F95DC;
    font-family: 'Sora-Bold';
    border-color: #2F95DC;
    margin-right: 15px;
    padding: 10px 20px;
    border-radius: 32px;
    margin-bottom: 10px;
}
.tools-list-active:hover, .tools-list:hover{
    background-color: #2F95DC;
}
.tools-list {
    background-color: transparent;
    border-color: #2F95DC;
    color: #2F95DC;
    font-family: Sora;
    margin-right: 15px;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 32px;
}
.agent-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.agent-name-management {
    margin-left: 15px;
}
.agent-management-wrapper{
    background-color: #ffffff;
    padding: 30px;
    /* margin-top: 20px; */
    border-radius: 15px;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
    width: 100%;
}
.agent-management-main-padding{
    padding: 30px !important;
}
.tool-icon{
    margin-right: 5px;
}
.agent-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.file-name {
    font-size: 14px;
    color: blue;
    text-decoration: underline;
}
.files-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
}
.dataset-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}
.agent-data-upload {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.date-display-wrapper {
    width: 48%;
}
.channel-description {
    font-size: 14px;
}
.channels-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    flex-wrap: wrap;
}
.channel {
    width: 30%;
    border: 1px solid #dddddd;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-right: 25px;
}
.icon-wrapper {
    text-align: center;
    background-color: #f6f6f6;
    padding: 15px 0;
}
.channel-info {
    padding: 15px;
}
.no-agent {
    color: #7a7a7a;
    font-size: 25px;
    font-weight: 700;
    margin-top: 50px;
}
.agent-creation-time {
    font-size: 12px;
    color: red;
}
.agent-creation {
    font-size: 14px;
}
.in-progress{
    background: #ECEFF3;
    border-radius: 39px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #929CAF;
    height: 30px;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}