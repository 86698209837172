.dashboard-content {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
}
.page-title {
    font-size: 14px;
}
.dashboard-content-wrapper {
    width: 100%;
}
.dashboard-section {
    width: 48%;
    border-radius: 20px;
    padding: 25px;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
    margin-bottom: 20px;
}

.prompt-header-title {
    font-size: 15px;
}
.prompts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 0.5px solid #dddddd;
}