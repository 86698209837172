@font-face {
  font-family: "Sora";
  src: local("Sora"),
    url("../fonts/Sora/Sora-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Sora-Bold";
  src: local("Sora-Bold"),
    url("../fonts/Sora/Sora-Bold.ttf") format("truetype");
}

/* Global Classes */
.sora-regular{
  font-family: Sora;
}
.sora-bold{
  font-family: Sora-Bold;
}
.regular-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.h-100vh{
  height: 100vh;
}
.mr-0{
  margin-right: 0px;
}
.fixed-container {
  height: 100vh;
  overflow-y: scroll;
}
.main-container{
  padding: 30px 100px;
  background-color: #F8F9FD;
}
.user-greetings {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #1E1E1E;
  text-align: right;
  margin-bottom: 10px;
}
.omni-content-wrapper {
  display: flex;
  justify-content: space-between;
}
.toggle-btns {
  background-color: #ececec;
  border: 1px solid #ececec;
  height: 18px;
  width: 42px;
}
.toggle-btns:checked {
  background-color: #2f95dc;
  border: 1px solid #2f95dc;
}
.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Auth screens */
.content-wrapper{
  background-image: url('../images/omniservice-auth-bg.png');
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.auth-logo{
  width: 70%;
}
.main-content-wrapper {
  display: flex;
}
.auth-left-content-wrapper {
  width: 50%;
  text-align: center;
}
.auth-right-content-wrapper {
  width: 90%;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  text-align: left;
  box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
}
.submit-btn {
  background-color: #2f95dc;
}
.submit-btn:focus {
  background-color: #2f95dc;
}
.submit-btn:hover {
  background-color: #2f95dc;
}
.not-a-menber {
  color: #A1A5B7;
  text-align: center;
}
.forgot-password{
  text-align: right;
}
.scroll-section {
  height: 80vh;
  overflow-y: scroll;
}
/*********** Side Menu ***********/
.sidemenu {
  background-color: #1e1e2d;
  min-height: 100vh !important;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
  padding: 1rem 15px 3rem 20px;
  height: 100vh;
}
.sidemenu-logo-wrapper {
  border-bottom: 0.5px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.right-content {
  min-height: 100vh !important;
}
.sidemenu-links {
  text-decoration: none;
  color: #949494;
  width: 100%;
}
.sidemenu-item {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.sidemenu-item-nested {
  margin-left: 50px;
  color: white;
}

.side-menu-icons {
  margin: 12px 14px;
}
.active {
  background: #2F95DC;
  border-radius: 14px;
}
.active-font {
  font-weight: bold;
  color: #ffffff;
}
.sidemenu-links:hover{
  color: #ffffff;
  font-weight: bold;
}
.logout {
  text-decoration: none;
  cursor: pointer;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2F95DC;
  color: #ffffff;
  padding: 10px;
  border-radius: 42px;
}
.logout:hover {
  color: #ffffff;
}
.logout-wrapper {
  bottom: 0;
  width: 20%;
  position: absolute;
}
.logout-btn {
  background: none;
  border: none;
  text-align: left;
  padding: 0;
}
.logout-btn:hover {
  background: none;
}