.setting-icons{
    margin-right: 10px;
}
.setting-content-wrapper {
    width: 100%;
}
.section-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.section-title {
    font-size: 20px;
}
.btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.setting-content {
    background-color: #ffffff;
    padding: 30px 70px;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
}
.setting-section-wrapper{
    width: 48%;
}
.toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
}