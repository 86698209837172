.new-message-dot {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 20px;
    position: absolute;
    bottom: 90%;
    left: 99.5%;
}

.inbox-content {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
}
.message-wrapper {
    background: #E4F3FD;
    border-radius: 6px;
    padding: 25px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
}
span.btn-text {
    margin-right: 10px;
}
.message-action {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}