.search-box {
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 17px;
    display: flex;
    justify-content: space-between;
}
.search-input {
    font-family: 'Sora';
    font-size: 16px;
    color: #000000;
    border: 0;
    background-color: transparent;
    width: 95%;
}
.page-title {
    margin-bottom: 20px;
}
.faq-content {
    background-color: #ffffff;
    padding: 20px 50px;
    margin: 20px 0;
    box-shadow: 0 0.25rem 1rem 0 rgb(47 91 234 / 13%);
    border-radius: 20px;
}
.faq-wrapper {
    width: 100%;
    margin-bottom: 10px;
}
.faq {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.faq-title {
    background: #E4F3FD;
    width: 90%;
    padding: 25px;
    height: 80px;
}
.expand-hide-btn {
    background: #ABD4F1;
    width: 10%;
    height: 80px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.faq-answer {
    background: #FFFFFF;
    border: 1px solid #E4F3FD;
    border-radius: 6px;
    margin-top: 10px;
    padding: 30px;
}
.section-value {
    font-size: 35px;
    margin-top: 20px;
}
.dashboard-section-title {
    margin-bottom: 20px;
}